.container .populer .title{
  text-align: center; margin-top: 20px;
    background-repeat: no-repeat;
    padding: 300px 0px 20px;
    background-size: cover;
    color:#fff;
    position: relative;
    background-position: center;
}
.container .populer .title::before{
  content: "";
  width: 100%;
min-height: 100px;
  background-color: rgba(0, 0, 0, 0.44);
  position: absolute;
  left: 0;
  bottom: 0;

}
.container .populer .title h1{position: relative;}
/** Slide Area Start*/

.container .populer .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .container .populer .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
  }
  
  .container .populer  .swiper-slide img {
    display: block;
    width: 100%;
  }
 /** Slide Area End*/
 .container .desc {margin: 80px 0px 20px; box-shadow: 0px 0px 10px #ddd; padding: 40px;}
 .container .desc h2 span:nth-child(1){color:orange}
 .container .desc h2 span:nth-child(2){color:rgb(255, 64, 0)}
 .container .desc h2 span:nth-child(3){color:rgb(199, 19, 19)}
 .container .desc p{line-height: 30px;}
 .about-insta{    text-decoration: none;font-size: 23px;  background:-webkit-linear-gradient(#062ba0, #b106b7);-webkit-background-clip: text;-webkit-text-fill-color: transparent;}
.about-insta i{text-transform: uppercase;background-image: linear-gradient( -225deg,#231557 0%,#44107a 29%,#ff1361 67%, #fff800 100%  );  background-size: auto auto;  background-clip: border-box;  background-size: 200% auto;  color: #fff;  background-clip: text;  text-fill-color: transparent; -webkit-background-clip: text; -webkit-text-fill-color:transparent;animation:textclip 2s linear infinite;display:inline-block;font-size: 70px;}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.videos{margin: 80px 0px 0px;     background-color: #f8f9fa; padding: 80px 0px;}

  @media (max-width:768px) {
    .container .populer .title{ margin-top: 57px; background-size: cover;padding: 240px 0px 20px;background-position-y: center; }
    .container .populer .title::before{  box-shadow: none;  min-height: 84px;}
  }

  