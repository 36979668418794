nav .container-fluid{margin: 0px 50px;} 
.navbar-fixed{
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
    border-bottom: 3px solid;
    border-image: linear-gradient( 98deg, rgba(255, 193, 7, 1) 0%, rgba(4, 56, 91, 0.9475140397956058) 52%, rgba(0, 228, 255, 0.7318277652858018) 86% ) 5;
}

nav .container-fluid .collapse ul{width: 100%;} 
nav .container-fluid .collapse ul li {padding: 0px 20px;line-height: 33px;}
nav .container-fluid .collapse ul li:last-child{line-height: 36px; }
nav .container-fluid .collapse ul li:last-child a, nav .container-fluid .tel a {text-decoration: none; color: #000;}
.animation-phone{   height:50px;   overflow:hidden;   margin-left: 1rem; }
.animation-phone > div > div {  height: 45px; min-width: 164px; line-height: 45px;padding: 0 10px; margin-bottom: 55px; display:inline-block; color:#fff;font-size: large;}
.animation-phone div:first-child {
    animation: text-animation 8s infinite;
  }
  
  .first div {
    background-color:#20a7d8;
  }
  .second div {
    background-color:#fdbf07
  }
  .third div {
    background-color:#6c757d;
  }

  @keyframes text-animation {
    0% {margin-top: 0;}
    10% {margin-top: 0;}
    20% {margin-top: -5.62rem;}
    30% {margin-top: -5.62rem;}
    40% {margin-top: -11.24rem;}
    60% {margin-top: -11.24rem;}
    70% {margin-top: -5.62rem;}
    80% {margin-top: -5.62rem;}
    90% {margin-top: 0;}
    100% {margin-top: 0;}
  }
@media (max-width:768px) {
    nav .container-fluid{margin: 0px 10px} 
    nav .container-fluid .mobile-navbar{display: flex ;width: 100%;justify-content: inherit;}
    .animation-phone > div > div{height: 50px; line-height: 50px; margin-bottom: 60px;}
    nav .container-fluid .collapse ul li a::before{content: "\f0da";font-family: Fontawesome; color:orange; margin-right: 10px; }
    nav .container-fluid .collapse ul li a{text-align: initial;}
}