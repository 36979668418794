.error{
    background-image: url("../../../public/images/home/servicesbg.png") ;
    background-repeat: no-repeat;
    height: calc(100vh - 75px);
    position: relative;
    margin-top: 75px;
}
.error a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    color: #32433f;
    display: block;
    font-family: 'DynaPuff', cursive;
}