.load-container{
    position: relative;
    height: 100vh;
    margin-top: 10%;
}
.load-container img{
    width: 250px;
    height: auto;
    position: absolute;
    z-index: 9;
    top: 20px;
    left: 40.5%;
}
.loader {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
    animation: animate 1.2s linear infinite;
    margin: 0 auto;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.loader:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #f1f1f1;
    border: solid white 10px;
    border-radius: 50%;
}