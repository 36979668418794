@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}
.footer {  font-family: "Poppins", sans-serif;  border-top: 3px solid;  border-image: linear-gradient(      98deg,      rgba(255, 193, 7, 1) 0%,      rgba(4, 56, 91, 0.9475140397956058) 52%,      rgba(0, 228, 255, 0.7318277652858018) 86%    )    5;  color: #000;  padding: 6px 0px;} 
.scroll-top div {  width: 50px;  height: 50px; margin: 0 5px; display: flex;  justify-content: center;  align-items: center;  background-color: #ffc107;  border-radius: 50%;} 
.scroll-top {  position: fixed;  bottom: 10px; right: 30px;display: flex;z-index: 9; padding: 1px 0px;} 
.scroll-top a{ width:50px; margin: 0 5px; border-radius: 50% ;box-shadow: 0 0 75px rgba(0,0,0,0.2);text-align: center; line-height: 50px;font-size: 35px;color: #fff}
.scroll-top .tel-footer{background: #288faa;font-size: 30px;}
.scroll-top .wp-m{background: #00e676;}
.scroll-top svg {  position: relative;  top: 0;  font-size: 18px;  transition: all 0.3s ease;}
.scroll-top:hover svg {  top: -8px;}
.wrapper {  display:flex;  list-style: none;  padding: 0;  margin: 0;}

.wrapper .icon {  position: relative;  background: #ffffff;  border-radius: 50%;  padding: 15px;  margin: 10px;  width: 40px;  height: 40px;  font-size: 22px;  display: flex;  justify-content: center;  align-items: center;  flex-direction: column;  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);  cursor: pointer;  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);}
  
.wrapper .tooltip {  position: absolute;  top: 0;  left: 0;  font-size: 14px;  background: #ffffff;  color: #ffffff;  padding: 5px 8px;  border-radius: 5px;  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);  opacity: 0;  pointer-events: none;  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);}
  
.wrapper .tooltip::before {  position: absolute;  content: "";  height: 8px;  width: 8px;  background: #ffffff;  bottom: -3px;  left: 20px;  transform: translate(-50%) rotate(45deg);  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);}
  
.wrapper .icon:hover .tooltip {  top: -45px;  opacity: 1;  visibility: visible;  pointer-events: auto;}
  
.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);}
.social-media{position: fixed; bottom: 10px; left: 30px; max-width: max-content; z-index: 9;}
.social-media .wrapper .icon a{color:#000}

/* .wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before,
.wrapper .facebook:hover a {background: #1877f2;color: #ffffff;} */

 
.insta-footer{background-image: linear-gradient( -225deg,#231557 0%,#44107a 29%,#ff1361 67%, #fff800 100%  );  background-size: auto auto;  background-clip: border-box;  background-size: 200% auto;  color: #fff;  background-clip:inherit; animation:textclip 2s linear infinite;}
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.footer .alt-footer{padding: 50px 0px 25px; background: #fbf8f8;}  
.footer .alt-footer h3{color:#a3888c}
.footer .alt-footer p{font-size: 14px; color: gray;}
.footer .alt-footer ul {list-style-type: none; text-align: start;}
.footer .alt-footer ul li{padding: 5px; border-radius: 6px;}
.footer .alt-footer ul li i{margin-right: 5px;}
.footer .alt-footer ul li:hover a{color:#fbf8f8}
.footer .alt-footer ul li:hover, .footer .alt-footer ul li a:hover{color:#fbf8f8; background:#a3888c; transition: all 0.3s ease;}
.footer .alt-footer ul li a{text-decoration: none; color:gray}

@media (max-width:768px) {
  .scroll-top {right: 10px; bottom: 20px;}
  .scroll-top a{width:40px; height: 40px; font-size: 30px;line-height: 40px;}
  .scroll-top div{width:40px; height: 40px; font-size: 20px;}
  .scroll-top .tel-footer{font-size: 20px;}
  .footer .alt-footer h3{text-align: start; padding-left: 15px;}
}